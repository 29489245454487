<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="checkouts"
      :loading="isLoadingCheckouts"
      :server-items-length="checkoutsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.invoice="{ item }">
        <v-btn
          v-if="$admin.can('checkout-view')"
          fab
          x-small
          color="blue-grey"
          :loading="is_loading_invoice && index == item.id"
          :disabled="is_loading_invoice && index == item.id"
          @click="getInvoice(item)"
        >
          <v-icon> mdi-download </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="$admin.can('checkout-view')"
          small
          class="mr-2"
          @click="showItem(item)"
        >
          mdi-eye
        </v-icon>
      </template>

      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("checkouts") }}</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <show-checkout v-if="$admin.can('checkout-view')"></show-checkout>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="8">
            <v-text-field
              v-model="options.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              clearable
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    ShowCheckout: () => import("./dialogs/ShowCheckout.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingCheckouts: "checkouts/isLoadingData",
      isVisibleShowDialog: "checkouts/isVisibleShowDialog",
      checkouts: "checkouts/getCheckouts",
      checkoutsMeta: "checkouts/meta",

      hubs: "hubs/activeHubs",
    }),
  },

  watch: {
    options: {
      handler: debounce(async function (params) {
        this.is_loading_checkout = true;

        await this.$store.dispatch("checkouts/list", params).then(() => {
          this.is_loading_checkout = false;
          if (params.page > this.checkoutsMeta.lastPage) {
            params.page = 1;
          }
        });
      }, 1000),
      deep: true,
    },

    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          itemsPerPage: 30,
          for: "inbound",
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },

  data() {
    return {
      search_hub: "",
      is_loading_hubs: false,
      is_loading_checkouts: false,
      options: {},
      index: 0,
      is_loading_invoice: false,
      is_loading_dn: false,

      headers: [
        {
          text: "ID",
          align: "start",
          value: "tracking_number",
        },
        {
          text: "total",
          align: "start",
          value: "total",
        },
        {
          text: "user",
          align: "start",
          value: "user.email",
        },

        {
          text: this.$t("invoice"),
          align: "center",
          value: "invoice",
          sortable: false,
          width: "15%",
        },

        {
          text: this.$t("began_at"),
          value: "began_at",
        },
        {
          text: this.$t("ended_at"),
          value: "ended_at",
        },
        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false,
          width: "15%",
        },
      ],
    };
  },

  methods: {
    showItem(item) {
      this.$store.dispatch("checkouts/openShowForm", item);
    },

    async getInvoice(item) {
      this.index = item.id;
      this.is_loading_invoice = true;
      await this.$store
        .dispatch("checkouts/getPDF_A4", item)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "checkout_" + item.tracking_number + ".pdf"
          );
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_invoice = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_invoice = false;
        });
    },
  },
};
</script>
